import React from "react";

function Footer() {
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="footer">
            <p>
              2023 © Admin Board. - <a href="/">Admin</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
